import {
  FormControl,
  TextField
} from '@mui/material'
import { ButtonForm, CustomTextField } from 'Components/Form/styles'
import { Column, Row } from 'Components/Grid'
import { MuiChipsInput } from 'mui-chips-input'
import { Controller } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

export const Detalhes = ({
  handleComplete,
  register,
  control,
  handleSubmit,
  errors,
  getValues,
}) => {
  // const theme = useTheme()
  const navigate = useNavigate()
  const onSubmit = () => {
    handleComplete()
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row justifyContent="center">
        <Column xs={12} sm={12} md={12} className="d-flex">
          <CustomTextField
            {...register('name', { required: 'Nome é obrigatório' })}
            label="Nome"
            autoFocus
            error={errors?.name ? true : false}
            sx={{ minHeight: '48px', margin: '0 auto 24px', width: '100%' }}
            helperText={errors?.name ? errors.name.message : null}
            InputLabelProps={{ shrink: true }}
          />
        </Column>
      </Row>
      <Row justifyContent="center">
        <Column xs={12} className="d-flex">
          <FormControl
            fullWidth
            sx={{ margin: '0 auto 24px' }}
          >
            <Controller
              control={control}
              name="tags"
              render={({ field: { onChange, value } }) => (
                <MuiChipsInput 
                  label="Tags" 
                  value={value} 
                  onChange={onChange}
                  InputLabelProps={{ shrink: true }}
                  placeholder="Digite e aperte enter"
                  renderChip={(Component, props) => {
                    return (
                      <Component 
                        {...props}
                        variant="outlined"
                        color="primary"
                        sx={{
                          width: 'fit-content',
                        }}
                      />
                    )
                  }}
                  sx={{
                    '& .MuiButtonBase-root.MuiChip-root.MuiChip-filled': {
                      width: 'fit-content'
                    },
                    '& .MuiButtonBase-root.MuiIconButton-root': {
                      width: 'fit-content',
                      marginTop: '0px'
                    }
                  }}/>
              )}
            ></Controller>
          </FormControl>
        </Column>
      </Row>
      <Row>
        <Column>
          <TextField
            {...register('description')}
            label="Descrição"
            multiline
            rows={4}
            sx={{ margin: '0 auto 24px', width: '100%' }}
            InputLabelProps={{ shrink: true }}
          />
        </Column>
      </Row>
      <Row justifyContent="end" alignItems="center">
        <Column xs={3} justifyContent="center" className="d-flex">
          <ButtonForm
            secondary
            id="cancel"
            label="cancelar"
            onClick={() => navigate('/data')}
          ></ButtonForm>
        </Column>
        <Column xs={2} justifyContent="center" className="d-flex">
          <ButtonForm label="Salvar" variant="submit" />
        </Column>
      </Row>
    </form>
  )
}
