import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { useAuthTenant } from 'store/auth';
import { getSelectedContent } from 'utils/select_utils';

import { Box, Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select, useTheme } from '@mui/material';
import { Column, Row } from 'Components/Grid';
import { ButtonForm } from 'Components/Form/styles';
import DotsLoader from 'Components/DotsLoader';

import { getAreasShort } from 'services/areas-service';
import { getProcessesShort } from 'services/processes-service';
import { getSystemsShort } from 'services/systems-service';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const getStyles = (name, personName, theme) => {
  return {
    fontWeight:
      personName?.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
};

export const Vinculos = ({ handleBack, handleComplete, control, handleSubmit, isLoading }) => {
  const selectedTenant = useSelector(useAuthTenant);
  const theme = useTheme();

  const onSubmit = () => {
    handleComplete();
  };

  const { data: areas } = useQuery(['getAreas'], async () => {
    if (selectedTenant.slug === undefined) return [];
    const result = await getAreasShort(selectedTenant.slug);
    return result?.data.length > 0 ? result?.data : [];
  });
  const { data: processes } = useQuery(['getProcesses'], async () => {
    if (selectedTenant.slug === undefined) return [];
    const result = await getProcessesShort(selectedTenant.slug);
    return result?.data.length > 0 ? result?.data : [];
  });
  const { data: systems } = useQuery(['getSystems'], async () => {
    if (selectedTenant.slug === undefined) return [];
    const result = await getSystemsShort(selectedTenant.slug);
    return result?.data.length > 0 ? result?.data : [];
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row justifyContent="center">
        <Column xs={12} className="d-flex">
          <Controller
            control={control}
            name="areas"
            render={({ field: { onChange, value } }) => (
              <FormControl fullWidth sx={{ margin: '0 auto 24px' }}>
                <InputLabel id="areas-label" shrink>
                  Áreas vinculadas
                </InputLabel>
                <Select
                  labelId="areas-label"
                  id="areas"
                  multiple
                  notched
                  value={getSelectedContent(areas, value)}
                  onChange={onChange}
                  input={<OutlinedInput id="areas-chip" label="Áreas vinculadas" />}
                  renderValue={selectedAreas => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selectedAreas?.map(selectedValue => (
                        <Chip
                          key={`area-chip-${selectedValue._id}`}
                          label={selectedValue.name}
                          variant="outlined"
                          color="primary"
                          sx={{
                            width: 'fit-content',
                          }}
                        />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {areas?.map(area => (
                    <MenuItem key={area._id} value={area} style={getStyles(area._id, value._id, theme)}>
                      {area.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          ></Controller>
        </Column>
      </Row>
      <Row justifyContent="center">
        <Column xs={12} className="d-flex">
          <Controller
            control={control}
            name="processes"
            render={({ field: { onChange, value } }) => (
              <FormControl fullWidth sx={{ margin: '0 auto 24px' }}>
                <InputLabel id="processes-label" shrink={true}>
                  Processos vinculados
                </InputLabel>
                <Select
                  labelId="processes-label"
                  id="processes"
                  multiple
                  notched
                  value={getSelectedContent(processes, value)}
                  onChange={onChange}
                  input={<OutlinedInput id="processes-chip" label="Processos vinculados" />}
                  renderValue={selectedProcess => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selectedProcess?.map(selectedValue => (
                        <Chip
                          key={`process-chip-${selectedValue._id}`}
                          label={selectedValue.name}
                          variant="outlined"
                          color="primary"
                          sx={{
                            width: 'fit-content',
                          }}
                        />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {processes?.map(process => (
                    <MenuItem key={process._id} value={process} style={getStyles(process._id, value._id, theme)}>
                      {process.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          ></Controller>
        </Column>
      </Row>
      <Row justifyContent="center">
        <Column xs={12} className="d-flex">
          <Controller
            control={control}
            name="systems"
            render={({ field: { onChange, value } }) => (
              <FormControl fullWidth sx={{ margin: '0 auto 24px' }}>
                <InputLabel id="systems-label" shrink={true}>
                  Sistemas vinculados
                </InputLabel>
                <Select
                  labelId="systems-label"
                  id="systems"
                  multiple
                  notched
                  value={getSelectedContent(systems, value)}
                  onChange={onChange}
                  input={<OutlinedInput id="systems-chip" label="Sistemas vinculados" />}
                  renderValue={selectedSystems => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selectedSystems?.map(selectedValue => (
                        <Chip
                          key={`system-chip-${selectedValue._id}`}
                          label={selectedValue.name}
                          variant="outlined"
                          color="primary"
                          sx={{
                            width: 'fit-content',
                          }}
                        />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {systems?.map(system => (
                    <MenuItem key={system._id} value={system} style={getStyles(system._id, value._id, theme)}>
                      {system.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          ></Controller>
        </Column>
      </Row>
      <Row justifyContent="end" alignItems="center">
        <Column xs={3} justifyContent="center" className="d-flex">
          <ButtonForm secondary id="cancel" label="cancelar" onClick={handleBack}></ButtonForm>
        </Column>
        <Column xs={2} justifyContent="center" className="d-flex">
          <ButtonForm label={isLoading ? <DotsLoader /> : 'Salvar'} variant="submit" />
        </Column>
      </Row>
    </form>
  );
};
