import { backendPrivate } from "services"

export const getSystems = async (tenant) => {
    return await backendPrivate.get(`/system?companyKey=${tenant}`)
}

export const getSystemsPaged = async (tenant, page, perPage) => {
    return await backendPrivate.get(`/system/paged?companyKey=${tenant}&page=${page}&perPage=${perPage}`)
}

export const getSystemsShort = async (tenant) => {
    return await backendPrivate.get(`/system/short-data?companyKey=${tenant}`)
}

export const getSystemsShortPaged = async (tenant, page, perPage) => {
    return await backendPrivate.get(`/system/short-data-paged?companyKey=${tenant}&page=${page}&perPage=${perPage}`)
}

export const getSystemsNames = async (tenant) => {
    return await backendPrivate.get(`/system/names?companyKey=${tenant}`)
}

export const getSystemById = async (id) => {
    return await backendPrivate.get(`/system/${id}`)
}

export const getSystemBoundsById = async (id) => {
    return await backendPrivate.get(`/system/${id}/bounds`)
}

export const addSystem = async (system) => {
    return await backendPrivate.post('/system', system)
}

export const editSystem = async (id, system) => {
    return await backendPrivate.put(`/system/${id}`, system)
}

export const deleteSystem = async (id) => {
    return await backendPrivate.delete(`/system/${id}`)
}