import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
/**
 * Componentes
 */
import { Container } from '../../../Components/Container';
import { TitleForm } from 'Components/Form/styles';
import { FormSuccess } from 'Components/Form/Success';
import { Row } from 'Components/Grid';
import { Detalhes } from './Details';
/** Services */
import { addData, editData, getDataById } from 'services/datas-service';
/** State Manager */
import { useAuthTenant } from 'store/auth';
import { useSelector } from 'react-redux';
/** Third Library Modal */
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

export const DataForm = () => {
  const selectedTenant = useSelector(useAuthTenant);
  const { id } = useParams();
  const SwalReact = withReactContent(Swal);
  const navigate = useNavigate();
  const [idData, setIdData] = useState(null);
  const [finished, setFinished] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      name: '',
      tags: [],
      description: '',
      companyKey: selectedTenant.slug,
    },
  });

  const handleComplete = async () => {
    try {
      const res = id ? await editData(id, getValues()) : await addData(getValues());
      if (res.status === 200 || res.status === 201) {
        setIdData(res.data._id ?? id);
        setFinished(true);
      } else {
        throw new Error('Ocorreu um erro ao tentar salvar/editar o Dado!');
      }
    } catch (error) {
      SwalReact.fire({
        title: 'Erro!',
        text: error.response.data.message ?? error.message,
        icon: 'error',
      });
    }
  };

  const handleReset = () => {
    reset();
    setFinished(false);
    setIdData(null);
    navigate('/data/add');
  };

  const getData = useCallback(async () => {
    try {
      const resData = await getDataById(id);
      Object.keys(resData.data).forEach(key => {
        setValue(key, resData.data[key]);
      });
    } catch (error) {
      SwalReact.fire({
        title: 'Erro!',
        text: error.response.data.message ?? error.message,
        icon: 'error',
      });
    }
  }, [id, setValue, SwalReact]);

  useEffect(() => {
    if (!id) return;

    getData();

    return () => {};
  }, [id, getData]);

  return (
    <Container
      title={`${id ? 'Editar' : 'Adicionar'} Tipo de dado`}
      breadcrumb="Mapeamento / Tipo de dados"
      linkPage="/data"
    >
      <div className="card-content">
        <Row>
          <TitleForm>Detalhes</TitleForm>
        </Row>
        {!finished && (
          <Detalhes
            control={control}
            register={register}
            handleComplete={handleComplete}
            handleSubmit={handleSubmit}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
          />
        )}
        {finished && (
          <FormSuccess
            title="Tipo de dado cadastrado com sucesso!"
            labelStartAgain="Cadastrar novo tipo de dado"
            handleStartAgain={() => handleReset()}
            labelShow="Ver detalhes"
            handleShow={() => navigate('/data/show/' + idData)}
          />
        )}
      </div>
    </Container>
  );
};
