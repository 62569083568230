import { useState } from 'react';
import { useQuery } from 'react-query';
/** Components */
import { DefaultTable } from 'Components/DefaultTable';
import { Container } from '../../Components/Container';
import { Information } from 'Components/Information';
import { tableHeader } from './table-header.js';
import { ModalConfirmDelete } from 'Components/ModalConfirmDelete';
import { PageLoaderList } from 'Components/PageLoader/List';
/** Services */
import { deleteAsset, getAssetsShortPaged } from 'services/assets-service';
/** State Managers */
import { useAuthTenant } from 'store/auth';
import { useSelector } from 'react-redux';
/** Third Lib Modal */
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { NoData } from 'Components/NoData';
import { Pagination, Stack } from '@mui/material';

export const Assets = () => {
  const selectedTenant = useSelector(useAuthTenant);
  const SwalReact = withReactContent(Swal);
  const [open, setOpen] = useState(false);
  const [reFetch, setRefetch] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [page, setPage] = useState(1);
  const {
    isLoading,
    error,
    data: pagedAssets,
  } = useQuery(['getAssets', reFetch, page], async () => {
    const result = await getAssetsShortPaged(selectedTenant.slug, page, 15);
    if (result?.data.data.length > 0) {
      return result?.data;
    }
    return {
      data: [],
      page,
      totalPages: 0,
    };
  });

  const onDelete = item => {
    setItemToDelete(item);
    setOpen(true);
  };

  const onClose = () => {
    setItemToDelete(null);
    setOpen(false);
  };

  const onConfirm = async () => {
    try {
      const result = await deleteAsset(itemToDelete?._id);
      if (result.status === 204) {
        onClose();
        setRefetch(!reFetch);
        SwalReact.fire({
          title: 'Sucesso!',
          text: 'Átivo removido com sucesso',
          icon: 'success',
        });
      } else {
        throw new Error('Ocorreu um erro ao tentar remover o Ativo!');
      }
    } catch (error) {
      SwalReact.fire({
        title: 'Erro!',
        text: error.response.data.message ?? error.message,
        icon: 'error',
      });
    }
  };

  return (
    <Container title="Ativos" breadcrumb="Mapeamento / Ativos" showButtonAdd linkPage="/assets">
      <Information
        title={'Ativos'}
        description={
          <p className="m-0">
            Os ativos ficam nos “bastidores”, suportando toda a nossa operação. Eles podem ser de três tipos:
            <br />
            <br />
            &emsp;• Pessoas: times, gestores, colaboradores, etc
            <br />
            &emsp;• Tecnologia: computadores, tablets, smartphones, servidores, bancos de dados, etc
            <br />
            &emsp;• Ambiente: datacenters, ambientes de nuvem (AWS, Google, Azure, etc), escritórios, etc
          </p>
        }
        style={{
          marginTop: '24px',
        }}
      />
      {isLoading && <PageLoaderList></PageLoaderList>}
      {error && <div>Ocorreu um erro ...</div>}
      {!isLoading && !error && pagedAssets.data?.length === 0 && <NoData description={'Não há ativos para exibir'} />}
      {!isLoading && !error && pagedAssets.data?.length > 0 && (
        <div className="container">
          <DefaultTable rows={tableHeader} cells={pagedAssets.data} onClickDelete={onDelete} link="assets" />
          <Stack sx={{ justifyContent: 'center', flexDirection: 'row', marginTop: '16px' }}>
            <Pagination
              count={pagedAssets.totalPages}
              onChange={(_, selectedPage) => {
                setPage(selectedPage);
              }}
              color="primary"
              page={page}
              showFirstButton
              showLastButton
            />
          </Stack>
          <ModalConfirmDelete
            description={
              <>
                Tem certeza que deseja excluir <strong>{` ${itemToDelete?.name} `}</strong> como ativo?
              </>
            }
            open={open}
            onClose={onClose}
            onConfirm={onConfirm}
          />
        </div>
      )}
    </Container>
  );
};
