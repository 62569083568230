import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import { routePermissions } from "route-permissions";
import { useAuthLoggedIn, useAuthTenant, useAuthUser } from "store/auth";
import { useSelector } from "react-redux";

import { AcceptPolicyVersion } from "Pages/AcceptPolicyVersion";
import AddGapAnalysis from "Pages/GapAnalisys/AddGapAnalysis";
import AddGapAnalisysReport from "Pages/Reports/GapAnalysis/Form";
import { Areas } from "Pages/Areas";
import { AreasForm } from "Pages/Areas/Form";
import { AreasShow } from "Pages/Areas/Show";
import { Assets } from "Pages/Assets";
import { AssetsForm } from "Pages/Assets/Form";
import { AssetsShow } from "Pages/Assets/Show";
import { CreateAccount } from "Pages/CreateAccount";
import { Companies } from "Pages/Companies";
import { CompaniesForm } from "Pages/Companies/Form";
import { CompaniesShow } from "Pages/Companies/Show";
import { CompaniesPartner } from "Pages/CompaniesPartner";
import { CompaniesPartnerForm } from "Pages/CompaniesPartner/Form";
import { CompaniesPartnerShow } from "Pages/CompaniesPartner/Show";
import { CompanyProfile } from "Pages/CompanyProfile";
import { Controls } from "Pages/Controls";
import { ControlsForm } from "Pages/Controls/Form";
import { ControlDetails } from "Pages/Controls/Show";
import ControlDeploymentLogDetails from "Pages/GapAnalisys/ControlDeploymentLogDatails";
import { Cookies } from "Pages/Cookies";
import { CookiesForm } from "Pages/Cookies/Form";
import { CookiesShow } from "Pages/Cookies/Show";
import { Dashboard } from "Pages/Dashboard";
// import { SniperDashboard } from "Pages/SniperDashboard";
// import { SniperMainDashboard } from "Pages/SniperMainDashboard";
import Dashboards from "Pages/GapAnalisys/Dashboards";
import { Data } from "Pages/Data";
import { DataForm } from "Pages/Data/Form";
import { DataShow } from "Pages/Data/Show";
import EditControl from "Pages/GapAnalisys/EditControl";
import ErrorBoundary from "Components/ErrorBoundary";
import Evolution from "Pages/GapAnalisys/Evolution";
import GapAnalisys from "Pages/GapAnalisys";
import GapAnalysisReportDetails from "Pages/Reports/GapAnalysis/Show";
import { hasPermission } from "utils/permission_utils";
import { Login } from "Pages/Login";
import { Map } from "Pages/Map";
import { NoPermission } from "Pages/NoPermission";
import { Offices } from "./Pages/Offices";
import { OnboardCompany } from "Pages/Onboard/Company";
import { OnboardCompanyAdmin } from "Pages/Onboard/CompanyAdmin";
import { OnboardChoosePlan } from "Pages/Onboard/ChoosePlan";
import { OnboardCancel } from "Pages/Onboard/Cancel";
import { OnboardSuccess } from "Pages/Onboard/Success";
import { OfficesForm } from "Pages/Offices/Form";
import { OfficesShow } from "Pages/Offices/Show";
import { PoliciesShow } from "Pages/Policies/Show";
import { PoliciesChecklistVersion } from "Pages/Policies/ChecklistVersion";
import { PoliciesShowAccepts } from "Pages/Policies/Show/Accepts";
import { Processes } from "Pages/Processes";
import { ProcessesForm } from "Pages/Processes/Form";
import { ProcessesShow } from "Pages/Processes/Show";
import { Policies } from "Pages/Policies";
import { PoliciesForm } from "Pages/Policies/Form";
import { ProcessesDatasShow } from "Pages/Processes/Show/Datas";
import ReportsRopa from "Pages/Reports/Ropa";
import { ReportsRopaForm } from "Pages/Reports/Ropa/Form";
import { ReportsRopaShow } from "Pages/Reports/Ropa/Show";
import ReportsGapAnalysis from "Pages/Reports/GapAnalysis";
import { SecurityStandards } from "Pages/SecurityStandard";
import { SecurityStandardForm } from "Pages/SecurityStandard/Form";
import { SecurityStandardDetails } from "Pages/SecurityStandard/Show";
import { Systems } from "Pages/Systems";
import { SystemsForm } from "Pages/Systems/Form";
import { SystemsShow } from "Pages/Systems/Show";
import { Users } from "Pages/Users";
import { UsersForm } from "Pages/Users/Form";
import { UsersGroups } from "Pages/UsersGroups";
import { UsersGroupsForm } from "Pages/UsersGroups/Form";
import { UsersGroupsShow } from "Pages/UsersGroups/Show";
import { UsersShow } from "Pages/Users/Show";
// import { UsersPermissions } from 'Pages/UsersPermissions'
import { VerifyCode } from "Pages/VerifyCode";
import { DataMapping } from "Pages/Processes/Form/DataMapping";
import { AuditLogs } from "Pages/AuditLogs";
import { PolicyVersions } from "Pages/Policies/Versions";
import { PolicyVersionForm } from "Pages/Policies/Versions/Form";
import { PolicyVersionShow } from "Pages/Policies/Versions/Show";

const Routing = () => {
  const isLoggedIn = useSelector(useAuthLoggedIn);
  const user = useSelector(useAuthUser);
  const selectedTenant = useSelector(useAuthTenant);

  const ProtectedRoute = ({
    isLoggedIn,
    onlyAdminVO = false,
    path,
    children,
  }) => {
    if (!isLoggedIn) {
      return <Navigate to="/login" replace />;
    }
    const routePermission = routePermissions.find(
      (route) => route.path === path
    );
    if (
      routePermission &&
      !hasPermission(user.permissions, routePermission.permission)
    ) {
      return <Navigate to="/no-permission" replace />;
    }

    if (
      onlyAdminVO &&
      (!["super_admin_global", "admin_global"].includes(user.roleActive) ||
        selectedTenant.slug !== "vo")
    ) {
      return <Navigate to="/no-permission" replace />;
    }

    return (
      <ErrorBoundary
        fallback={(error) => {
          return <p>{error.message}</p>;
        }}
      >
        {children}
      </ErrorBoundary>
    );
  };

  return (
    <Routes>
      <Route
        exact
        path="/"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/dashboard">
            <Navigate to="/dashboard" />
          </ProtectedRoute>
        }
      />
      {/* <Route
        exact
        path="/sniper-main-dashboard"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/sniper-main-dashboard">
            <SniperMainDashboard />
          </ProtectedRoute>
        }
      /> */}
      {/* <Route
        exact
        path="/sniper-dashboard"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/sniper-dashboard">
            <SniperDashboard />
          </ProtectedRoute>
        }
      /> */}
      <Route
        exact
        path="/areas"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/areas">
            <Areas />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/areas/add"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/areas/add">
            <AreasForm />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/areas/edit/:id"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/areas/edit">
            <AreasForm />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/areas/show/:id"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/areas/show">
            <AreasShow />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/assets"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/assets">
            <Assets />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/assets/add"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/assets/add">
            <AssetsForm />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/assets/edit/:id"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/assets/edit">
            <AssetsForm />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/assets/show/:id"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/assets/show">
            <AssetsShow />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/audit-logs"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/audit-logs">
            <AuditLogs />
          </ProtectedRoute>
        }
      />
      <Route exact path="/create-account" element={<CreateAccount />} />
      <Route
        exact
        path="/cookies"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/cookies">
            <Cookies />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/cookies/add"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/cookies/add">
            <CookiesForm />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/cookies/edit/:id"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/cookies/edit">
            <CookiesForm />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/cookies/show/:id"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/cookies/show">
            <CookiesShow />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/onboard-company/:idOnboard"
        element={<OnboardCompany />}
        />
      <Route
        exact
        path="/onboard-company-admin/:slug"
        element={<OnboardCompanyAdmin />}
        />
      <Route
        exact
        path="/onboard-choose-plan/:slug"
        element={<OnboardChoosePlan />}
        />
      <Route
          exact
          path="/onboard-success/:slug/:plan"
          element={<OnboardSuccess />}
          />
      <Route
          exact
          path="/onboard-cancel/:slug"
          element={<OnboardCancel />}
          />
      <Route
        exact
        path="/company-profile"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/company-profile">
            <CompanyProfile />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/companies"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} onlyAdminVO path="/companies">
            <Companies />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/companies/add"
        element={
          <ProtectedRoute
            isLoggedIn={isLoggedIn}
            onlyAdminVO
            path="/companies/add"
          >
            <CompaniesForm />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/companies/edit/:id"
        element={
          <ProtectedRoute
            isLoggedIn={isLoggedIn}
            onlyAdminVO
            path="/companies/edit"
          >
            <CompaniesForm />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/companies/show/:id"
        element={
          <ProtectedRoute
            isLoggedIn={isLoggedIn}
            onlyAdminVO
            path="/companies/show"
          >
            <CompaniesShow />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/companies-partner"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/companies-partner">
            <CompaniesPartner />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/companies-partner/add"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/companies-partner/add">
            <CompaniesPartnerForm />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/companies-partner/edit/:id"
        element={
          <ProtectedRoute
            isLoggedIn={isLoggedIn}
            path="/companies-partner/edit"
          >
            <CompaniesPartnerForm />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/companies-partner/show/:id"
        element={
          <ProtectedRoute
            isLoggedIn={isLoggedIn}
            path="/companies-partner/show"
          >
            <CompaniesPartnerShow />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/data"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/data">
            <Data />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/data/add"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/data/add">
            <DataForm />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/data/edit/:id"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/data/edit">
            <DataForm />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/data/show/:id"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/data/show">
            <DataShow />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/dashboard"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn}>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route exact path="/login" element={<Login />} />
      <Route
        exact
        path="/map"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/map">
            <Map />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/offices"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/offices">
            <Offices />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/offices/add"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/offices">
            <OfficesForm />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/offices/edit/:id"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/offices">
            <OfficesForm />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/offices/show/:id"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/offices">
            <OfficesShow />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/policies"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/policies">
            <Policies />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/policies/add"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/policies/add">
            <PoliciesForm />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/policies/checklist/:id/versions/:idVersion"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/policies/edit">
            <PoliciesChecklistVersion />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/policies/edit/:id"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/policies/edit">
            <PoliciesForm />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/policies/show/:id"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/policies/show">
            <PoliciesShow />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/policies/show/:id/versions/:idVersion"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/policies/show">
            <PolicyVersionShow />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/policies/show/:id/versions/:idVersion/accepts"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/policies/show">
            <PoliciesShowAccepts />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/policies/:id/versions"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/policies/edit">
            <PolicyVersions />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/policies/:id/versions/add"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/policies/edit">
            <PolicyVersionForm />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/policies/:id/versions/edit/:idVersion"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/policies/edit">
            <PolicyVersionForm />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/processes"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/processes">
            <Processes />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/processes/add"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/processes/add">
            <ProcessesForm />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/processes/edit/:id"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/processes/edit">
            <ProcessesForm />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/processes/edit/:id/mapping"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/processes/edit">
            <DataMapping />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/processes/edit/:id/mapping/:mappingId"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/processes/edit">
            <DataMapping />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/processes/show/:id"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/processes/show">
            <ProcessesShow />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/processes/show/:id/datas"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/processes/show">
            <ProcessesDatasShow />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/processes/show/:id/mapping/:mappingId"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/processes/show">
            <ProcessesDatasShow />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/systems"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/systems">
            <Systems />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/systems/add"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/systems/add">
            <SystemsForm />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/systems/edit/:id"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/systems/edit">
            <SystemsForm />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/systems/show/:id"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/systems/show">
            <SystemsShow />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/users"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/users">
            <Users />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/users/add"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/users/add">
            <UsersForm />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/users/edit/:id"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/users/edit">
            <UsersForm />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/users/show/:id"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/users/show">
            <UsersShow />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/users-groups"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/users-groups">
            <UsersGroups />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/users-groups/add"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/users-groups/add">
            <UsersGroupsForm />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/users-groups/edit/:id"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/users-groups/edit">
            <UsersGroupsForm />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/users-groups/show/:id"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/users-groups/show">
            <UsersGroupsShow />
          </ProtectedRoute>
        }
      />
      {/* <Route exact path="/users-permissions" element={(
        <ProtectedRoute isLoggedIn={isLoggedIn}>
          <UsersPermissions />
        </ProtectedRoute>
      )} /> */}
      <Route
        exact
        path="/accept-policy-version/:idPolicyVersionUser"
        element={
          <ErrorBoundary
            fallback={(error) => <p>Ocorreu um erro: {error.message}</p>}
          >
            <AcceptPolicyVersion />
          </ErrorBoundary>
        }
      />
      <Route
        exact
        path="/verify-code/:email"
        element={
          <ErrorBoundary
            fallback={(error) => <p>Ocorreu um erro: {error.message}</p>}
          >
            <VerifyCode />
          </ErrorBoundary>
        }
      />
      <Route exact path="no-permission" element={<NoPermission />} />
      <Route
        exact
        path="/controls"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/controls">
            <Controls />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/controls/add"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/controls/add">
            <ControlsForm />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/controls/edit/:id"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/controls/edit">
            <ControlsForm />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/controls/show/:id"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/controls/show">
            <ControlDetails />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/security-standards"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/security-standards">
            <SecurityStandards />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/security-standards/add"
        element={
          <ProtectedRoute
            isLoggedIn={isLoggedIn}
            path="/security-standards/add"
          >
            <SecurityStandardForm />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/security-standards/edit/:id"
        element={
          <ProtectedRoute
            isLoggedIn={isLoggedIn}
            path="/security-standards/edit"
          >
            <SecurityStandardForm />
          </ProtectedRoute>
        }
      />

      <Route
        exact
        path="/security-standards/show/:id"
        element={
          <ProtectedRoute
            isLoggedIn={isLoggedIn}
            path="/security-standards/show"
          >
            <SecurityStandardDetails />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/gap-analysis/:id/dashboards"
        element={
          <ProtectedRoute
            isLoggedIn={isLoggedIn}
            path="/gap=analisys"
          >
            <Dashboards />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/gap-analysis/:id/dashboards/control/:idControl/deployment-log"
        element={
          <ProtectedRoute
            isLoggedIn={isLoggedIn}
            path="/gap-analysis"
          >
            <EditControl />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/gap-analysis/:id/dashboards/control/:idControl/deployment-log/:idDeploymentLog"
        element={
          <ProtectedRoute
            isLoggedIn={isLoggedIn}
            path="/gap-analysis"
          >
            <EditControl />
          </ProtectedRoute>
        }
      />

      <Route
        exact
        path="/gap-analysis/:id/dashboards/deployment-log/:idDeploymentLog"
        element={
          <ProtectedRoute
            isLoggedIn={isLoggedIn}
            path="/gap-analysis"
          >
            <ControlDeploymentLogDetails />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/gap-analysis"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/gap-analysis">
            <GapAnalisys />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/gap-analysis/add"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/gap-analysis/add">
            <AddGapAnalysis />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/gap-analysis/evolution"
        element={
          <ProtectedRoute
            isLoggedIn={isLoggedIn}
            path="/gap-analysis"
          >
            <Evolution />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/reports/ropa"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/reports/ropa">
            <ReportsRopa />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/reports/ropa/add"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/reports/ropa/add">
            <ReportsRopaForm />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/reports/ropa/show/:id"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/reports/ropa">
            <ReportsRopaShow />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/reports/gap-analysis"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/reports/gap-analysis">
            <ReportsGapAnalysis />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/reports/gap-analysis/add"
        element={
          <ProtectedRoute
            isLoggedIn={isLoggedIn}
            path="/reports/gap-analysis/add"
          >
            <AddGapAnalisysReport />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/reports/gap-analysis/show/:id"
        element={
          <ProtectedRoute
            isLoggedIn={isLoggedIn}
            path="/reports/gap-analysis/add"
          >
            <GapAnalysisReportDetails />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default Routing;
