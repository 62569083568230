import { backendPrivate } from "services"

export const getAssets = async (tenant) => {
    return await backendPrivate.get(`/asset?companyKey=${tenant}`)
}
export const getAssetsPaged = async (tenant, page, perPage) => {
    return await backendPrivate.get(`/asset/paged?companyKey=${tenant}&page=${page}&perPage=${perPage}`)
}

export const getAssetsShort = async (tenant) => {
    return await backendPrivate.get(`/asset/short-data?companyKey=${tenant}`)
}

export const getAssetsShortPaged = async (tenant, page, perPage) => {
    return await backendPrivate.get(`/asset/short-data-paged?companyKey=${tenant}&page=${page}&perPage=${perPage}`)
}

export const getAssetsNames = async (tenant) => {
    return await backendPrivate.get(`/asset/names?companyKey=${tenant}`)
}

export const getAssetById = async (id) => {
    return await backendPrivate.get(`/asset/${id}`)
}

export const getAssetBoundsById = async (id) => {
    return await backendPrivate.get(`/asset/${id}/bounds`)
}

export const addAsset = async (asset) => {
    return await backendPrivate.post('/asset', asset)
}

export const editAsset = async (id, asset) => {
    return await backendPrivate.put(`/asset/${id}`, asset)
}

export const deleteAsset = async (id) => {
    return await backendPrivate.delete(`/asset/${id}`)
}