import { Box, Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select, useTheme } from '@mui/material';
import { Column, Row } from 'Components/Grid';
import { Controller } from 'react-hook-form';
import { ButtonForm } from 'Components/Form/styles';
import { useQuery } from 'react-query';
import { getAssetsShort } from 'services/assets-service';
import { getSystemsShort } from 'services/systems-service';
import { getAreasShort } from 'services/areas-service';
import { getSelectedContent } from 'utils/select_utils';
import { useAuthTenant } from 'store/auth';
import { useSelector } from 'react-redux';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const getStyles = (name, personName, theme) => {
  return {
    fontWeight:
      personName?.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
};

export const Vinculos = ({ handleBack, handleComplete, control, handleSubmit }) => {
  const selectedTenant = useSelector(useAuthTenant);
  const theme = useTheme();

  const onSubmit = () => {
    handleComplete();
  };

  const { data: areas } = useQuery(['getAreas'], async () => {
    if (selectedTenant.slug === undefined) return [];
    const result = await getAreasShort(selectedTenant.slug);
    return result?.data.length > 0 ? result?.data : [];
  });

  const { data: systems } = useQuery(['getSystems'], async () => {
    if (selectedTenant.slug === undefined) return [];
    const result = await getSystemsShort(selectedTenant.slug);
    return result?.data.length > 0 ? result?.data : [];
  });

  const { data: assets } = useQuery(['getAssets'], async () => {
    if (selectedTenant.slug === undefined) return [];
    const result = await getAssetsShort(selectedTenant.slug);
    return result?.data.length > 0 ? result?.data : [];
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row justifyContent="center">
        <Column xs={12} className="d-flex">
          <Controller
            control={control}
            name="areas"
            render={({ field: { onChange, value } }) => (
              <FormControl fullWidth sx={{ margin: '0 auto 24px' }}>
                <InputLabel id="areas-label" shrink>
                  {' '}
                  Áreas vinculadas
                </InputLabel>
                <Select
                  labelId="areas-label"
                  id="areas"
                  multiple
                  notched
                  value={getSelectedContent(areas, value)}
                  onChange={onChange}
                  input={<OutlinedInput id="areas-chip" label="Áreas vinculados" />}
                  renderValue={selectedAreas => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selectedAreas?.map(selectedValue => (
                        <Chip
                          key={`area-chip-${selectedValue._id}`}
                          label={selectedValue.name}
                          variant="outlined"
                          color="primary"
                          sx={{
                            width: 'fit-content',
                          }}
                        />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {areas?.map(item => (
                    <MenuItem key={item._id} value={item} style={getStyles(item._id, value._id, theme)}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          ></Controller>
        </Column>
      </Row>
      <Row justifyContent="center">
        <Column xs={12} className="d-flex">
          <Controller
            control={control}
            name="systems"
            render={({ field: { onChange, value } }) => (
              <FormControl fullWidth sx={{ margin: '0 auto 24px' }}>
                <InputLabel id="systems-label" shrink>
                  Sistemas vinculados
                </InputLabel>
                <Select
                  labelId="systems-label"
                  id="systems"
                  multiple
                  notched
                  value={getSelectedContent(systems, value)}
                  onChange={onChange}
                  input={<OutlinedInput id="systems-chip" label="Sistemas vinculados" />}
                  renderValue={selectedSystem => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selectedSystem?.map(selectedValue => (
                        <Chip
                          key={`system-chip-${selectedValue._id}`}
                          label={selectedValue.name}
                          variant="outlined"
                          color="primary"
                          sx={{
                            width: 'fit-content',
                          }}
                        />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {systems?.map(item => (
                    <MenuItem key={item._id} value={item} style={getStyles(item._id, value._id, theme)}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          ></Controller>
        </Column>
      </Row>
      <Row>
        <Column xs={12} className="d-flex">
          <Controller
            control={control}
            name="assets"
            render={({ field: { onChange, value } }) => (
              <FormControl fullWidth sx={{ margin: '0 auto 24px' }}>
                <InputLabel id="assets-label" shrink>
                  Ativos vinculados
                </InputLabel>
                <Select
                  labelId="assets-label"
                  id="assets"
                  multiple
                  notched
                  value={getSelectedContent(assets, value)}
                  onChange={onChange}
                  input={<OutlinedInput id="assets-chip" label="Ativos vinculados" />}
                  renderValue={selectedAsset => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selectedAsset?.map(selectedValue => (
                        <Chip
                          key={`asset-chip-${selectedValue._id}`}
                          label={selectedValue.name}
                          variant="outlined"
                          color="primary"
                          sx={{
                            width: 'fit-content',
                          }}
                        />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {assets?.map(item => (
                    <MenuItem key={item._id} value={item} style={getStyles(item._id, value._id, theme)}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          ></Controller>
        </Column>
      </Row>
      <Row justifyContent="end" alignItems="center" className="mt-3">
        <Column xs={3} justifyContent="center" className="d-flex">
          <ButtonForm secondary id="cancel" label="cancelar" onClick={handleBack}></ButtonForm>
        </Column>
        <Column xs={2} justifyContent="center" className="d-flex">
          <ButtonForm label="Salvar" variant="submit" />
        </Column>
      </Row>
    </form>
  );
};
