import backend, { backendPrivate } from "services"
export const getUsers = async (
    tenant,
    page,
    perPage,
    typeFilter,
    searchContent,
    active
) => {
    return await backendPrivate.get(`/user?companyKey=${tenant}&page=${page}&perPage=${perPage}&typeFilter=${typeFilter}&searchContent=${searchContent}&active=${active}`)
}

export const getUsersName = async (tenant) => {
    return await backendPrivate.get(`/user/names?companyKey=${tenant}`)
}

export const getUsersNameEmailByCompany = async (tenant) => {
    return await backendPrivate.get(`/user/names-email-by-company?companyKey=${tenant}`)
}

export const getUserById = async (id) => {
    return await backendPrivate.get(`/user/${id}`)
}

export const addUser = async (data) => {
    return await backend.post('/user', data)
}

export const createUser = async (data) => {
    return await backend.post('/user/create-user', data)
}

export const createUserOnboarding = async (data) => {
    return await backend.post('/user/onboarding', data)
}

export const editUser = async (id, slug, data) => {
    return await backendPrivate.put(`/user/${id}/${slug}`, data)
}

export const deleteUser = async (id) => {
    return await backendPrivate.delete(`/user/${id}`)
}

export const enableUser = async (id) => {
    return await backendPrivate.post(`/user/${id}/enable`)
}

export const validateUserEmail = async (email) => {
    return await backend.get(`/user/is-valid-email/${email}`)
}