import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
/** Components */
import { FormSuccess } from 'Components/Form/Success';
import { StepperForm } from 'Components/StepperForm';
import { Container } from '../../../Components/Container';
import { Detalhes } from './Details';
import { Vinculos } from './Bonds';
import { Row } from 'Components/Grid';
import { TitleForm } from 'Components/Form/styles';
/** Services */
import { addSystem, editSystem, getSystemById } from 'services/systems-service';
/** State Managers */
import { useAuthTenant } from 'store/auth';
import { useSelector } from 'react-redux';
/** Third Lib Modal */
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { useQuery } from 'react-query';
import { PageLoaderForm } from 'Components/PageLoader/Form';

const steps = ['Detalhes', 'Vínculos'];

export const SystemsForm = () => {
  const selectedTenant = useSelector(useAuthTenant);
  const { id } = useParams();
  const SwalReact = withReactContent(Swal);
  const navigate = useNavigate();
  const [idSystem, setIdSystem] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [finished, setFinished] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      name: '',
      criticity: '',
      user: '',
      tags: [],
      pii: false,
      saas: false,
      developedInHouse: false,
      dataBase: '',
      description: '',
      areas: [],
      processes: [],
      assets: [],
      companyKey: null,
    },
  });

  const totalSteps = () => {
    return steps.length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleNext = () => {
    const newActiveStep = isLastStep() ? activeStep : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const formatArea = area => {
    return {
      _id: area._id,
      name: area.name,
      description: area.description,
      user: area.user,
      criticity: area.criticity,
    };
  };

  const formatAsset = asset => {
    return {
      _id: asset._id,
      name: asset.name,
      description: asset.description,
      user: asset.user,
      criticity: asset.criticity,
    };
  };

  const formatProcess = process => {
    return {
      _id: process._id,
      name: process.name,
      description: process.description,
      user: process.user,
      criticity: process.criticity,
    };
  };

  const formatForm = dados => {
    return {
      name: dados.name,
      criticity: dados.criticity,
      user: dados.user,
      tags: dados.tags.length > 0 ? dados.tags : [],
      pii: dados.pii ?? false,
      saas: dados.saas ?? false,
      developedInHouse: dados.developedInHouse ?? false,
      dataBase: dados.dataBase ?? '',
      description: dados.description ?? '',
      areas: dados.areas.length > 0 ? dados.areas.map(area => formatArea(area)) : [],
      processes: dados.processes.length > 0 ? dados.processes.map(process => formatProcess(process)) : [],
      assets: dados.assets.length > 0 ? dados.assets.map(asset => formatAsset(asset)) : [],
      companyKey: dados.companyKey ?? selectedTenant.slug,
    };
  };

  const handleComplete = async () => {
    try {
      setIsLoadingForm(true);
      const formattedForm = formatForm(getValues());
      const res = id ? await editSystem(id, formattedForm) : await addSystem(formattedForm);
      if (res.status === 200 || res.status === 201) {
        setIdSystem(res.data._id ?? id);
        setFinished(true);
        setIsLoadingForm(false);
      } else {
        throw new Error('Ocorreu um erro ao tentar salvar/editar o Sistema!');
      }
    } catch (error) {
      setIsLoadingForm(false);
      SwalReact.fire({
        title: 'Erro!',
        text: error.response.data.message ?? error.message,
        icon: 'error',
      });
    }
  };

  const handleReset = () => {
    reset();
    setActiveStep(0);
    setFinished(false);
    setIdSystem(null);
    navigate('/systems/add');
  };

  const getTitleStep = () => {
    if (finished) return <></>;

    if (activeStep === 0)
      return (
        <Row>
          <TitleForm>Detalhes do sistema</TitleForm>
        </Row>
      );

    return (
      <Row>
        <TitleForm>Vinculos do sistema</TitleForm>
      </Row>
    );
  };

  const {
    isLoading,
    error,
    data: system,
  } = useQuery(['getSystem', id], async () => {
    if (!id) return null;

    const resSystem = await getSystemById(id);

    if (!resSystem.data) return null;

    return resSystem.data;
  });

  useEffect(() => {
    if (!id) return;
    if (!system) return;

    Object.keys(system).forEach(key => {
      setValue(key, system[key]);
    });

    return () => {};
  }, [id, system, setValue]);

  const showForm = () => {
    if (id) return !isLoading && !error;
    return true;
  };

  return (
    <Container title={`${id ? 'Editar' : 'Adicionar'} Sistema`} breadcrumb="Mapeamento / Sistemas" linkPage="/systems">
      {isLoading && <PageLoaderForm />}
      {showForm() && (
        <>
          <StepperForm activeStep={activeStep} steps={steps} />
          <div className="card-content">
            {getTitleStep()}
            {activeStep === 0 && (
              <Detalhes
                control={control}
                register={register}
                handleNext={handleNext}
                handleSubmit={handleSubmit}
                errors={errors}
                getValues={getValues}
              />
            )}
            {activeStep === 1 && !finished && (
              <Vinculos
                control={control}
                register={register}
                handleBack={handleBack}
                handleComplete={handleComplete}
                handleSubmit={handleSubmit}
                isLoading={isLoadingForm}
              />
            )}
            {finished && (
              <FormSuccess
                title="Sistema cadastrado com sucesso!"
                labelStartAgain="Cadastrar novo sistema"
                handleStartAgain={() => handleReset()}
                labelShow="Ver detalhes"
                handleShow={() => navigate('/systems/show/' + idSystem)}
              />
            )}
          </div>
        </>
      )}
    </Container>
  );
};
