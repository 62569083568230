import { Column, Row } from 'Components/Grid';
import { CustomTableReport } from '../styles';

const AreaAndProcesses = ({ dados }) => {
  return (
    <Column className="px-0">
      <CustomTableReport>
        <thead>
          <tr>
            <th>Áreas</th>
            <th>Processos</th>
          </tr>
        </thead>
        <tbody>
          {dados &&
            dados.map(item => (
              <tr>
                <td>{item.areaName}</td>
                <td>
                  {item.processes &&
                    item.processes.map(subitem => (
                      <>
                        {subitem}
                        <br />
                      </>
                    ))}
                </td>
              </tr>
            ))}
        </tbody>
      </CustomTableReport>
    </Column>
  );
};

const ProcessesWithoutArea = ({ dados }) => {
  return (
    <Column className="px-0">
      <CustomTableReport>
        <thead>
          <tr>
            <th>Processos Sem Área de Negócio</th>
          </tr>
        </thead>
        <tbody>
          {dados &&
            dados.map(item => (
              <tr>
                <td>{item}</td>
              </tr>
            ))}
        </tbody>
      </CustomTableReport>
    </Column>
  );
};

const AreasDetails = ({ dados }) => {
  return (
    <Column className="px-0">
      <CustomTableReport>
        <thead>
          <tr>
            <th className="text-left less-p">Área de Negócio</th>
            <th className="text-left less-p">Sistemas/Ativos</th>
            <th className="text-left less-p">Dados Pessoais</th>
            <th className="text-left less-p">Dados Pessoais Sensíveis</th>
            <th className="text-left less-p">Base Legais</th>
          </tr>
        </thead>
        <tbody>
          {dados &&
            dados.map(item => (
              <tr>
                <td>{item.areaName}</td>
                <td>
                  {item.assetsAndSystems &&
                    item.assetsAndSystems.map(subitem => (
                      <>
                        {subitem}
                        <br />
                      </>
                    ))}
                </td>
                <td>
                  {item.dadosPessoais &&
                    item.dadosPessoais.map(subitem => (
                      <>
                        {subitem}
                        <br />
                      </>
                    ))}
                </td>
                <td>
                  {item.dadosPessoaisSensiveis &&
                    item.dadosPessoaisSensiveis.map(subitem => (
                      <>
                        {subitem}
                        <br />
                      </>
                    ))}
                </td>
                <td>
                  {item.legalBases &&
                    item.legalBases.map(subitem => (
                      <>
                        {subitem}
                        <br />
                      </>
                    ))}
                </td>
              </tr>
            ))}
        </tbody>
      </CustomTableReport>
    </Column>
  );
};

export const GeneralView = ({ generalView }) => {
  return (
    <>
      <Row className="mt-4">
        {generalView.areaAndProcesses && <AreaAndProcesses dados={generalView.areaAndProcesses} />}
      </Row>
      <Row className="mt-4">
        {generalView.processesWithoutArea && <ProcessesWithoutArea dados={generalView.processesWithoutArea} />}
      </Row>
      <Row className="mt-4">{generalView.areasDetails && <AreasDetails dados={generalView.areasDetails} />}</Row>
    </>
  );
};
